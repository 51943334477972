/* Login.css (Updated for sales-oriented page with full-screen top panel) */

/* General container styling for the entire login page */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  padding: 0;
}

/* Top panel styling for full-screen effect */
.top-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background: url('/public/background.gif') no-repeat center center;
  background-size: cover;
  position: relative;
}

.top-panel .brand-logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
  align-self: flex-start;
}

.top-panel h1 {
  font-size: 4rem;
  font-weight: bold;
  margin: auto 0;
}

.top-panel .scroll-down {
  font-size: 1.2rem;
  margin-bottom: 10px;
  align-self: flex-end;
}

/* Header section styling */
.header-section {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.header-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.header-section p {
  font-size: 1.2rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Step-by-step explanation section styling */
.steps-section {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.steps-section h2 {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 30px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.step {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.step h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  color: #666;
}

/* Call-to-action section styling */
.cta-section {
  margin-top: 20px;
  padding-bottom: 30px; /* Added padding at the bottom to ensure button doesn't touch the bottom of the screen */
}

/* Button styling */
.signin-button {
  padding: 15px 25px;
  font-size: 1.2rem;
  color: white;
  background-color: #4285f4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signin-button:hover {
  background-color: #357ae8;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .top-panel {
    padding: 20px; /* Adjust padding for better fit on mobile */
  }

  .top-panel h1 {
    font-size: 3rem;
  }

  .header-section h1,
  .step p {
    font-size: 1rem;
  }

  .steps-container {
    gap: 20px;
  }

  .signin-button {
    font-size: 1rem;
    padding: 12px 20px;
  }

  .top-panel .scroll-down {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
