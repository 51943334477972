/* Chat.css */

/* Remove the .input-area styles from here */

/* Keep the rest of your Chat.css as is */

.chat-container {
    width: 95%;
    max-width: 900px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Prevents unwanted scroll on the main container */
    flex: 1; /* Allows the chat window to expand and scroll properly */
  }
  
  .chat-window {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    /* Adjust height dynamically to account for the input area */
    height: calc(100vh - 150px); /* Adjust the value if needed */
  }
  
  /* Message Styles */
  .message {
    margin: 10px 0;
    padding: 8px 12px;
    border-radius: 5px;
    max-width: 60%;
    word-wrap: break-word;
  }
  
  /* User message styling */
  .message.user {
    align-self: flex-end;
    background-color: rgba(35, 141, 254, 255);
    color: white;
    text-align: right;
    border-radius: 15px 15px 0 15px;
  }
  
  /* Assistant message styling */
  .message.assistant {
    align-self: flex-start;
    background-color: rgba(233, 233, 235, 255);
    color: black;
    text-align: left;
    border-radius: 15px 15px 15px 0;
  }