/* ContextEditor.css */

/* Overall container styling */
.context-editor {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Center the container */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Heading styling */
.context-editor h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

/* Input field styling */
.context-editor input,
.context-editor textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  resize: vertical;
  box-sizing: border-box;
}

/* Button styling */
.context-editor button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.context-editor button:hover {
  background-color: #0056b3;
}

/* Loading state button styling */
.context-editor button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Spacing adjustments for the form elements */
.context-editor .form-section {
  margin-bottom: 20px;
}

.context-editor .form-section:last-child {
  margin-bottom: 0;
}

/* Public link section styling */
.public-link-section {
  margin-bottom: 20px;
}

.public-link-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.public-link-section p {
  margin-bottom: 10px;
  color: #666;
  font-size: 1rem;
}

.public-link-display {
  font-size: 1rem;
  color: #007bff;
  word-break: break-all;
  margin-bottom: 15px;
}

/* Copy button styling */
.copy-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Copy button hover effect */
.copy-button:hover {
  background-color: #0056b3;
}

/* User controls styling for welcome text and sign-out button */
.user-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 102;
}

.welcome-text {
  font-size: 0.6rem;
  color: #333;
  margin: 0;
  text-align: left;
}

.sign-out-button {
  padding: 2px 4px;
  font-size: 0.6rem;
  background-color: #ff4b4b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-out-button:hover {
  background-color: #d43f3f;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .public-link-section {
    padding: 10px;
    width: 100%;
  }

  .context-editor {
    padding: 10px;
    width: 100%;
    margin: 0 auto; /* Ensure container is centered on mobile */
  }

  .copy-button {
    padding: 1px 3px;
    font-size: 0.6rem;
  }

  .welcome-text {
    font-size: 0.6rem;
  }

  .sign-out-button {
    font-size: 0.6rem;
  }
}

