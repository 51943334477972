/* PublicChat.css */

/* Global box-sizing reset and margin/padding reset */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Remove default margins and paddings */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #ededed;
  color-scheme: light;
  overflow-x: hidden;
}

/* General styling for public chat container */
.public-chat-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 180px; /* Increased padding to ensure header fits properly */
  padding-bottom: 70px; /* Reserve space for input */
  position: relative;
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

/* Header Area Styling */
.header-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 170px; /* Adjusted height to provide more space for title and icons */
  display: flex;
  align-items: center;
  padding: 0 30px; /* Increased horizontal padding */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 101;
}

/* Styling for the header icons */
.left-icon, .right-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.left-icon {
  margin-right: auto; /* Pushes the header-center to the right */
}

.right-icon {
  margin-left: auto; /* Pushes the header-center to the left */
}

.left-icon img, .right-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Center area containing the GIF and title */
.header-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-basis: 60%;
}

/* Styling for the header GIF */
.header-gif {
  width: 88px; /* Increased size by 25% */
  height: 88px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px; /* Space between GIF and text */
}

/* Styling for the title */
.title {
  margin: 0;
  font-size: 1.8em; /* Increased font size for better visibility */
  line-height: 1;
  text-align: center;
  word-break: break-word; /* Ensure long titles do not overflow */
}

/* Chat container styling */
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
  overflow-y: auto;
}

/* Message styles */
.message {
  margin: 10px 0;
  padding: 8px 12px;
  border-radius: 5px;
}

.message.user {
  align-self: flex-end;
  background-color: rgb(35, 141, 254);
  color: #fff;
}

.message.assistant {
  align-self: flex-start;
  background-color: rgb(233, 233, 235);
}

/* Input area styling fixed to the bottom */
.input-area {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Adjusted padding */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.input-area input {
  flex: 1;
  padding: 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.input-area button {
  padding: 15px 25px;
  font-size: 1em;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .header-area {
    height: 150px; /* Reduced height for mobile fit */
    padding: 0 15px;
  }

  .header-gif {
    width: 70px;
    height: 70px;
  }

  .title {
    font-size: 1.2em;
  }

  .left-icon, .right-icon {
    width: 30px;
    height: 30px;
  }

  .public-chat-container {
    padding-top: 150px; /* Adjust padding to accommodate header on mobile */
    padding-bottom: 60px;
  }

  .input-area {
    height: 60px;
    padding: 10px 15px;
  }

  .input-area input,
  .input-area button {
    padding: 10px;
  }
}
